import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleCollection from "../components/article-collection"
import { useMetaCollectionJapaneseEvents } from "../meta/collection/japanese-events"

const JapaneseEventsPage = () => {
  const description = useMetaCollectionJapaneseEvents().plain

  return (
    <Layout>
      <SEO title="Japanese events" description={description} />

      <StaticQuery
        query={graphql`
          query JapaneseEvents {
            japaneseEvents: allMarkdownRemark(
              filter: { fields: { contentKind: { eq: "japanese-event" } } }
              sort: { fields: frontmatter___title, order: ASC }
            ) {
              ...ArticleCollectionFragment
            }
          }
        `}
        render={data => (
          <ArticleCollection
            articleTypePath="/japanese-events"
            articles={data.japaneseEvents}
          />
        )}
      />
    </Layout>
  )
}

export default JapaneseEventsPage
