import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql } from "gatsby"
import v from "../styles/vars"
import { px } from "../styles/units"
import breakpoints from "../styles/breakpoints"

import Card from "./card"
import EncloseFloats from "./enclose-floats"

const ArticleGrid = styled.div`
  display: grid;

  grid-gap: ${px(v.gap)};
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  ${breakpoints.downHorizontalNavigation} {
    grid-gap: ${px(v.mobileGap)};
  }
`

const SummaryOverflow = styled(EncloseFloats)`
  line-height: 1.65;
  height: ${1.65 * 3.5}rem;
  overflow: hidden;
  position: relative;

  & > p:last-of-type {
    margin-bottom: 0;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    height: ${1.65 * 1}rem;
    background: linear-gradient(0deg, #fff, transparent);
  }
`

const ArticleCollection = ({ imageVariant, articleTypePath, articles }) => (
  <ArticleGrid>
    {articles.nodes.map(article => (
      <Card
        as="article"
        key={article.id}
        imageVariant={imageVariant}
        image={
          article.frontmatter.imageFit === "cover"
            ? article.frontmatter.imageCover
            : article.frontmatter.imageContain
        }
        imageFallback={{ height: 150 }}
        title={article.frontmatter.title}
        buttonText="Continue reading →"
        to={`${articleTypePath}/${article.fields.slug}/`}
      >
        <SummaryOverflow>
          <p>{article.excerpt}</p>
        </SummaryOverflow>
      </Card>
    ))}
  </ArticleGrid>
)
ArticleCollection.propTypes = {
  imageVariant: Card.propTypes.imageVariant,
  articleTypePath: PropTypes.string,
  articles: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        file: PropTypes.shape({
          name: PropTypes.string,
        }),
        frontmatter: PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.any,
        }),
        excerpt: PropTypes.string,
      })
    ),
  }),
}

export const BaseArticleCollectionFragment = graphql`
  fragment BaseArticleCollectionFragment on MarkdownRemarkConnection {
    nodes {
      id
      fields {
        slug
      }
      frontmatter {
        title
        imageFit: image_fit
      }
      excerpt(format: PLAIN)
    }
  }
`

export const ArticleCollectionFragment = graphql`
  fragment ArticleCollectionFragment on MarkdownRemarkConnection {
    ...BaseArticleCollectionFragment
    nodes {
      frontmatter {
        imageCover: image {
          ... on File {
            childImageSharp {
              fluid(
                maxWidth: 400
                maxHeight: 150
                background: "rgba(255, 255, 255, 1)"
                fit: COVER
                cropFocus: ATTENTION
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        imageContain: image {
          ... on File {
            childImageSharp {
              fluid(
                maxWidth: 400
                maxHeight: 150
                background: "rgba(255, 255, 255, 1)"
                fit: CONTAIN
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default ArticleCollection
